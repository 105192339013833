<template>
  <div class="inventory">
    <Loading :transparent="true" v-if="loading" />

    <Taber
      v-if="settings"
      :tabs="Object.keys(settings)"
      @update-tab="
        (e) => {
          currentTab = e
        }
      "
    />

    <div v-if="tab && tab.type == 'form'" class="practice-form-container">
      <form @submit.prevent="putData">
        <SettingsForm
          form-type="update"
          @errors="errors = $event"
          @input="input = $event"
          :initInput="input"
          :postLoading="postLoading"
          :form="tab.form"
        />

        <div class="button-wrap">
          <div class="buttons">
            <button
              :disabled="postLoading || !inputChanged"
              @click.prevent="handleCancel"
              class="cancel-button"
            >
              Cancel
            </button>

            <button
              :disabled="errors.length > 0 || postLoading || !inputChanged"
              type="submit"
              class="submit-button"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>

    <div v-if="tab && tab.component && !loading" class="tab-component">
      <component
        :is="tab.component"
        :loading="loading"
        :postLoading="postLoading"
        :model="tab.model"
      >
      </component>
    </div>

    <div class="stub" v-if="false && tab">
      <pre>
          <code>
              {{JSON.stringify(tab.model.data, null, 4)}}
          </code>
      </pre>
    </div>

    <transition name="fade">
      <FloatMsg
        v-if="msg !== ''"
        :err="err"
        :msg="msg"
        :callback="
          () => {
            msg = ''
            err = false
          }
        "
      />
    </transition>
  </div>
</template>

<script>
import Loading from "@/components/Loading"
import FloatMsg from "@/components/FloatMsg"
import Taber from "@/components/Taber"
import SettingsForm from "@/components/SettingsForm"
import PracticeUser from "@/models/practice_users"
import Provider from "@/models/providers"
import Reminder from "@/models/reminders"
import VisitReason from "@/models/visit_reason"
import VisitReasonTable from "@/components/visit_reasons/VisitReasonTable"
import ReminderTable from "@/components/reminders/ReminderTable"
import UserTable from "@/components/UserTable"
import ProviderTable from "@/components/ProviderTable"
import Practice, { practiceSettingsForm } from "@/models/practices"
import { EventBus } from "@/event-bus"

var practice = new Practice({
  remotePath: "/api/practice-settings/practice",
})

var appointment = new Practice({
  remotePath: "/api/practice-settings/appointment",
})

let reminder = new Reminder({
  columns: [
    "name",
    "providers",
    "hoursBefore",
    "active",
    "sendType",
    "textMessage",
  ],
})

var user = new PracticeUser({
  columns: ["name", "role", "practice", "last_login"],
})

var provider = new Provider({
  remotePath: "/api/practice-settings/providers",
  columns: [
    "name",
    "providerImageSrc",
    "includeInSearch",
    "enableScheduling",
    "scheduleIncrementMinutes",
  ],
})

var visitReason = new VisitReason({})

export default {
  name: "PracticeSettings",
  props: [],
  components: {
    Taber,
    Loading,
    FloatMsg,
    SettingsForm,
    UserTable,
    ProviderTable,
    VisitReason,
    VisitReasonTable,
  },

  data() {
    return {
      msg: "",
      err: false,
      errors: [],
      loading: false,
      postLoading: false,
      form: {},
      formShowing: false,
      input: {},
      initialInput: {},
      currentTab: null,
      tab: null,

      settings: {
        Info: {
          type: "form",
          model: practice,
          form: practiceSettingsForm("info"),
        },

        //      'EMR': {
        //        type: 'form',
        //        model: practice,
        //        form: practiceSettingsForm('emr'),
        //      },
        Providers: {
          type: "display",
          model: provider,
          component: ProviderTable,
        },

        Users: {
          type: "display",
          model: user,
          component: UserTable,
        },

        Appointments: {
          type: "form",
          model: appointment,
          form: practiceSettingsForm("appointment"),
        },

        "Visit Reasons": {
          type: "display",
          component: VisitReasonTable,
          model: visitReason,
        },

        Reminders: {
          type: "display",
          component: ReminderTable,
          model: reminder,
        },
      },
    }
  },

  mounted() {
    EventBus.$on("reset", this.reset)
    EventBus.$on("msg", (e) => {
      console.log("msg: ")
      this.msg = e
    })
  },

  destroyed() {
    console.log("Practicesettings destroyed")
    EventBus.$off("reset")
    EventBus.$off("msg")
  },

  methods: {
    initInput() {
      this.input = {}
      this.initialInput = {}

      if (this.tab.type == "form") {
        let keys = this.tab.form
        let input = {}

        for (var k in keys) {
          try {
            input[k] = this.tab.model.data[k]
            this.initialInput[k] = this.tab.model.data[k] || ''
          } catch (e) {
            console.log("form still loading...")
          }
        }

        this.input = { ...this.input, ...input }
      }

      this.loading = false
    },

    handleCancel() {
      this.initInput()
    },

    reset: async function () {
      this.loading = true
      const accessToken = await this.$auth.getTokenSilently()

      let auth = {
        accessToken: accessToken,
        user: this.$auth.user,
        role: this.$auth.appMetadata.role,
        practice_id: this.$auth.appMetadata.practice_id,
      }

      try {
        this.tab.model.setAuth(auth)
        await this.fetchData()
        this.initInput()
      } catch {
        this.loading = false
      }
    },

    fetchData: async function () {
      this.loading = true

      await this.tab.model.fetchData()
      this.loading = false
    },

    putData: async function () {
      this.postLoading = true
      await this.tab.model.putData(this.input)
      this.postLoading = false

      this.msg = this.tab.model.msg
      this.reset()
    },
  },

  computed: {
    inputChanged() {
      return JSON.stringify(this.input) != JSON.stringify(this.initialInput)
    },
  },

  watch: {
    "currentTab": async function () {
      this.tab = this.settings[this.currentTab]
      await this.reset()
    },

    $route() {
      this.reset()
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.practice-form-container {
  padding-bottom: 64px;
}

form {
  width: 600px;
  margin: 16px auto auto;
}

input[type="date"].form-field {
  -webkit-min-logical-width: calc(100% - 16px);
  background-image: url("~@/assets/images/cal.png");
  background-position: left 94% bottom 50%;
  background-repeat: no-repeat, no-repeat, repeat-x;
  background-color: white;
}

input[type="date"]::before {
  content: attr(placeholder);
  position: absolute;
  color: #999999;
}

input[type="date"] {
  color: #ffffff;
}

input[type="date"]:focus,
input[type="date"]:valid {
  color: #666666;
}

input[type="date"]:focus::before,
input[type="date"]:valid::before {
  content: "";
}

::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.add-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  z-index: 5000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
}

.add-overlay-box {
  position: fixed;
  overflow-y: hidden;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  background: #f1f2f1;
  border-radius: 4px;

  width: 380px;

  z-index: 5500;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 16px 24px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 24px 16px 24px;
}

@media (max-width: 640px) {
  .add-overlay {
    width: 100vw;
    height: 100%;
    overflow-y: auto;
  }

  .add-overlay-box {
    overflow-y: scroll;
    top: 50%;
    left: 50%;

    position: fixed;

    width: 100%;
    height: 100%;
  }

  .modal-header {
    padding: 12px 12px 0px !important;
  }

  form {
    width: 93% !important;
    padding: 12px !important;
    margin: 0 auto;
  }

  .button-wrap {
    margin: 0 auto;
  }

  .buttons {
    width: 93% !important;
  }

  .modal-header {
    padding: 12px 12px 0px !important;
  }

  form {
    width: 93% !important;
    padding: 12px !important;
    margin: 0 auto;
  }

  .button-wrap {
    margin: 0 auto;
  }

  .buttons {
    width: 93% !important;
  }
}

pre,
code {
  padding: 16px;
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.stub {
  border-radius: 6px;
  background: black;
  color: limegreen;
  max-width: 95%;
  margin: auto;
  text-align: left;
}

.field-wrap {
  text-align: left;
}

.field-wrap label {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #1f1f1f;
  opacity: 0.5;
  margin: 8px 0px;
}

.buttons {
  width: 600px;
  margin: auto;
}

.button-wrap {
  background: #1f1f1f;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;

  position: fixed;
  width: 100%;
  margin: 16px auto auto;
  height: 72px;

  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

.cancel-button,
.next-button,
.submit-button {
  width: 48%;
  height: 56px;
  border-radius: 4px;
  display: inline;
  background: white;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.cancel-button,
.cancel-button:focus,
.cancel-button:active {
  outline: none;
  margin: 8px 8px 0px 0px;
  color: #8c8c8c;
  outline: unset;
  border: 1px solid #8c8c8c;
}

.submit-button {
  background: #25d97f;
  margin: 8px 0px 8px 0px;
  border: 1px solid #25d97f;
  color: #ffffff;
}

button:disabled,
button.submit-button:disabled {
  color: white !important;
  background: #54575a;
  border: none;
}
</style>
