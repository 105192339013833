import Model from "@/models/model"

export default class Practice extends Model {
  constructor(props) {
    super(props)

    let defaultToolConfig = {
      export: true,
      search: true,
      newForm: true,
      multiUpdate: false,
      updateForm: true,
      formStepCount: 3,
      bigForm: true,
    }

    let defaultSettingsConfig = {
      newForm: false,
      multiUpdate: false,
      pageUpdate: true,
      formStepCount: 1,
      bigForm: true,
    }

    this.type = "practice"
    this.remotePath = props.remotePath || "/api/admin/practices"
    this.toolConfig = props.toolConfig
      ? { ...defaultToolConfig, ...props.toolConfig }
      : defaultToolConfig

    this.settingsConfig = props.settingsConfig
      ? { ...defaultSettingsConfig, ...props.settingsConfig }
      : defaultSettingsConfig
  }

  formatPayload(input) {
    let payload = super.formatPayload(input)

    console.log("FORMAT PAYLOAD: " + JSON.stringify(payload))

    for (var key in payload) {
      payload[key] = ["true", "false"].includes(payload[key])
        ? eval(payload[key])
        : payload[key]
    }

    return payload
  }

  async deleteData(data) {
    for (var i in data) {
      await super.deleteData(data[i].uid)
    }
  }

  async putData(data) {
    console.log("PUT DATA: " + JSON.stringify(data))
    var id = data.uid
    let payload = this.formatPayload(data)
    console.log("PUT DATA PAYLOAD: " + JSON.stringify(payload))
    await super.putData(id, payload)
  }

  schema() {
    return {
      name: {
        header: "Name",
        klass: "no-transform",
        display: true,
        newForm: true,
        updateForm: true,
        placeholder: "Practice Name",
        type: "text",
        required: true,
        step: 1,
        styler: () => {
          return { textAlign: "left" }
        },
      },

      streetAddress: {
        newForm: true,
        updateForm: true,
        placeholder: "Address",
        type: "text",
        required: true,
        step: 1,
      },

      city: {
        header: "City",
        klass: "no-transform",
        display: true,
        newForm: true,
        updateForm: true,
        placeholder: "City",
        type: "text",
        required: true,
        step: 1,
      },

      state: {
        newForm: true,
        updateForm: true,
        placeholder: "State",
        type: "select",
        formStyle: () => {
          return {
            paddingRight: "6px",
            margin: "0px",
            width: "48%",
            display: "inline-flex",
          }
        },
        required: true,
        options: stateOptions(),
        step: 1,
      },

      zipCode: {
        newForm: true,
        updateForm: true,
        placeholder: "Zip Code",
        type: "text",
        formStyle: () => {
          return {
            margin: "0px",
            width: "50%",
            display: "inline-flex",
          }
        },
        required: true,
        step: 1,
      },

      phoneNumber: {
        newForm: true,
        updateForm: true,
        placeholder: "Phone Number",
        type: "text",
        required: true,
        step: 1,
        onChange: (input) => {
          let num = input.replace(/\D[^.]/g, "")

          if (num.length > 9) {
            num =
              num.slice(0, 3) + "-" + num.slice(3, 6) + "-" + num.slice(6, 15)
          }

          return num
        },
      },

      email: {
        newForm: true,
        updateForm: true,
        placeholder: "Practice Email",
        type: "email",
        required: true,
        step: 1,
        pattern:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      },

      customerNumber: {
        newForm: true,
        updateForm: true,
        placeholder: "Sientra Customer Number",
        label: "Sientra Customer Number",
        type: "text",
        required: false,
        step: 1,
      },

      repEmail: {
        newForm: true,
        updateForm: true,
        placeholder: "Sientra Rep Email",
        label: "Sientra Rep Email",
        type: "email",
        required: false,
        step: 1,
        pattern:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      },

      allerganAccountNumber: {
        newForm: true,
        updateForm: true,
        placeholder: "Allergan Account Number",
        label: "Allergan Account Number",
        type: "text",
        required: false,
        step: 1,
      },

      mentorAccountNumber: {
        newForm: true,
        updateForm: true,
        placeholder: "Mentor Account Number",
        label: "Mentor Account Number",
        type: "text",
        required: false,
        step: 1,
      },

      nUsername: {
        newForm: true,
        updateForm: true,
        placeholder: "Nextech Username",
        label: "Nextech Username",
        type: "text",
        required: false,
        step: 2,
      },

      nClientID: {
        newForm: true,
        updateForm: true,
        placeholder: "Nextech Client ID",
        label: "Nextech Client ID",
        type: "text",
        required: false,
        step: 2,
      },

      nPracticeID: {
        newForm: true,
        updateForm: true,
        placeholder: "Nextech Practice ID",
        label: "Nextech Practice ID",
        type: "text",
        required: false,
        step: 2,
      },

      nPassword: {
        newForm: true,
        updateForm: true,
        placeholder: "Nextech Password",
        label: "Update Password",
        type: "password",
        required: false,
        step: 2,
      },

      twilioAccountSID: {
        newForm: true,
        updateForm: true,
        placeholder: "Twilio Account SID",
        label: "Twilio Account SID",
        type: "text",
        required: false,
        step: 3,
      },

      twilioPhoneNumber: {
        newForm: true,
        updateForm: true,
        placeholder: "Twilio Phone Number",
        label: "Twilio Phone Number",
        type: "text",
        required: false,
        step: 3,
      },

      twilioAuthToken: {
        newForm: true,
        updateForm: true,
        placeholder: "Twilio Auth Token",
        label: "Twilio Auth Token",
        type: "text",
        required: false,
        step: 3,
      },

      uid: {
        required: true,
        updateForm: true,
        type: "hidden",
      },

      allerganRepEmail: {
        newForm: true,
        updateForm: true,
        placeholder: "Allergan Rep Email",
        label: "Allergan Rep Email",
        type: "email",
        required: false,
        step: 1,
        pattern:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      },

      mentorRepEmail: {
        newForm: true,
        updateForm: true,
        placeholder: "Mentor Rep Email",
        label: "Mentor Rep Email",
        type: "email",
        required: false,
        step: 1,
        pattern:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      },
    }
  }
}

export function practiceSettingsForm(tab) {
  return {
    appointment: {
      minimumNotice: {
        type: "number",
        placeholder: "Minimum Appt Notice",
        label: "Minimum Appt Notice",
        klass: "half-left",
        required: false,
      },

      businessHrsForNotice: {
        type: "select",
        placeholder: "Use Business Hours For Notice",
        label: "Use Business Hours For Notice",
        klass: "half-right",
        required: false,
        options: {
          Yes: true,
          No: false,
        },
      },

      emailConfirmation: {
        type: "select",
        placeholder: "Send email confirmation",
        label: "Send email confirmation",
        klass: "half-left",
        required: false,
        options: {
          Yes: true,
          No: false,
        },
      },

      cancelableOnline: {
        type: "select",
        placeholder: "Patients can cancel online",
        label: "Patients can cancel online",
        klass: "half-right",
        required: false,
        options: {
          Yes: true,
          No: false,
        },
      },

      bookedNotificationEmail: {
        type: "text",
        placeholder: "Appt Booked Notification Email",
        label: "Appt Booked Notification Email",
        required: false,
      },

      bookedNotificationSms: {
        type: "text",
        placeholder: "Appt Booked Notification SMS",
        label: "Appt Booked Notification SMS",
        required: false,
      },

      defaultPatientSex: {
        type: "select",
        placeholder: "Default Sex",
        label: "The default sex to show on the appt-booking form",
        required: false,
        options: {
          "(None)": "none",
          Female: "female",
          Male: "male",
        },
      },

      uid: {
        required: true,
        type: "hidden",
      },
    },

    emr: {
      uid: {
        required: true,
        type: "hidden",
      },
      nUsername: {
        type: "text",
        display: "Nextech Username",
        label: "Nextech Username",
        required: false,
      },

      nClientID: {
        type: "text",
        display: "Nextech Client ID",
        label: "Nextech Client ID",
        required: false,
      },

      nPracticeID: {
        type: "text",
        display: "Nextech Practice ID",
        label: "Nextech Practice ID",
        required: false,
      },

      nPassword: {
        type: "password",
        display: "Nextech Password",
        label: "Update/Set Password",
        required: false,
      },
    },
    info: {
      uid: {
        required: true,
        type: "hidden",
      },
      name: {
        required: true,
        type: "text",
        display: "Practice Name",
      },
      streetAddress: {
        required: true,
        type: "text",
        display: "Address",
      },
      streetAddress: {
        required: true,
        type: "text",
        display: "Address",
      },
      state: {
        required: true,
        klass: "half-left",
        type: "select",
        options: stateOptions(),
        display: "State",
      },
      zipCode: {
        required: true,
        klass: "half-right",
        type: "text",
        display: "Zip Code",
      },
      phoneNumber: {
        required: true,
        type: "text",
        display: "Phone Number",
        pattern: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
      },
      email: {
        required: true,
        type: "email",
        display: "Email Address",
        pattern:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      },
      enableReminders: {
        type: "select",
        placeholder: "Enable Reminders?",
        label: "Enable Reminders",
        required: true,
        options: {
          Yes: true,
          No: false,
        },
      },
      customerNumber: {
        display: "Sientra Customer Number",
        label: "Sientra Customer Number",
        type: "text",
        required: false,
      },
      repEmail: {
        display: "Sientra Rep Email",
        label: "Sientra Rep Email",
        type: "email",
        required: false,
        pattern:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      },
      allerganAccountNumber: {
        display: "Allergan Account Number",
        label: "Allergan Account Number",
        type: "text",
        required: false,
      },
      allerganRepEmail: {
        display: "Allergan Rep Email",
        label: "Allergan Rep Email",
        type: "email",
        required: false,
        pattern:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      },
      mentorAccountNumber: {
        display: "Mentor Account Number",
        label: "Mentor Account Number",
        type: "text",
        required: false,
      },
      mentorRepEmail: {
        display: "Mentor Rep Email",
        label: "Mentor Rep Email",
        type: "email",
        required: false,
        pattern:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      },
    },
    visitReason: {
      showProvider: {
        type: "select",
        placeholder: "Patient Sees Provider",
        label: "Patient Sees Provider",
        klass: "half-right",
        required: false,
        options: {
          Yes: true,
          No: false,
        },
      },
    },
  }[tab]
}

export function stateOptions() {
  return {
    Alabama: "AL",
    Alaska: "AK",
    Arizona: "AZ",
    Arkansas: "AR",
    California: "CA",
    Colorado: "CO",
    Connecticut: "CT",
    Delaware: "DE",
    "District Of Columbia": "DC",
    Florida: "FL",
    Georgia: "GA",
    Hawaii: "HI",
    Idaho: "ID",
    Illinois: "IL",
    Indiana: "IN",
    Iowa: "IA",
    Kansas: "KS",
    Kentucky: "KY",
    Louisiana: "LA",
    Maine: "ME",
    Maryland: "MD",
    Massachusetts: "MA",
    Michigan: "MI",
    Minnesota: "MN",
    Mississippi: "MS",
    Missouri: "MO",
    Montana: "MT",
    Nebraska: "NE",
    Nevada: "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    Ohio: "OH",
    Oklahoma: "OK",
    Oregon: "OR",
    Pennsylvania: "PA",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    Tennessee: "TN",
    Texas: "TX",
    Utah: "UT",
    Vermont: "VT",
    Virginia: "VA",
    Washington: "WA",
    "West Virginia": "WV",
    Wisconsin: "WI",
    Wyoming: "WY",
  }
}
