var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table"},[(!_vm.$route.path.endsWith('/admin'))?_c('div',{staticClass:"brand-selection-container"},[_c('div',{staticClass:"brand-selection"},[_c('div',{staticClass:"brand-item",class:{ 'selected-brand': _vm.selectedBrand === 'Sientra' },on:{"click":function($event){_vm.selectedBrand = 'Sientra'}}},[_vm._v("Sientra")]),_vm._v(" "),_c('div',{staticClass:"brand-item",class:{ 'selected-brand': _vm.selectedBrand === 'Allergan' },on:{"click":function($event){_vm.selectedBrand = 'Allergan'}}},[_vm._v("Allergan")]),_vm._v(" "),_c('div',{staticClass:"brand-item",class:{ 'selected-brand': _vm.selectedBrand === 'Mentor' },on:{"click":function($event){_vm.selectedBrand = 'Mentor'}}},[_vm._v("Mentor")]),_vm._v(" "),_c('div',{staticClass:"brand-item",class:{ 'selected-brand': _vm.selectedBrand === 'Motiva' },on:{"click":function($event){_vm.selectedBrand = 'Motiva'}}},[_vm._v("Motiva")])])]):_vm._e(),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.showNewForm && _vm.model.toolConfig.newForm)?_c('NewForm',{attrs:{"model":_vm.model,"scanner":_vm.scanner,"config":_vm.model.toolConfig,"post-loading":_vm.postLoading,"type":_vm.model.type},on:{"on-submit":function($event){return _vm.handleAddSubmission($event)},"toggle-click":function($event){return _vm.handleAddClick($event)}}}):_vm._e()],1),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.showUpdateForm && _vm.model.toolConfig.updateForm)?_c('UpdateForm',{attrs:{"scanner":_vm.scanner,"model":_vm.model,"selected":_vm.selected,"post-loading":_vm.postLoading,"config":_vm.model.toolConfig,"type":_vm.model.type,"identifier":_vm.model.selectActions
        ? _vm.model.selectActions()['update']['identifier']
        : null},on:{"on-submit":function($event){return _vm.handleUpdateSubmission($event)},"on-delete":function($event){return _vm.handleDelete($event)},"toggle-click":function($event){return _vm.handleUpdateToggle($event)}}}):_vm._e()],1),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.showProcessForm && _vm.model.toolConfig.processForm)?_c('ProcessForm',{attrs:{"model":_vm.model.processForm,"selected":_vm.selected,"post-loading":_vm.postLoading,"type":_vm.model.processForm.type,"stepCount":_vm.model.processForm.toolConfig.formStepCount},on:{"on-submit":function($event){return _vm.handleProcessSubmission($event)},"toggle-click":function () {
        _vm.showProcessForm = !_vm.showProcessForm;
      }}}):_vm._e()],1),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.showDeletesConfirm)?_c('DeletesConfirm',{attrs:{"selected":_vm.selected,"post-loading":_vm.postLoading,"identifier":_vm.model.selectActions()['delete']['identifier'],"type":_vm.model.type},on:{"on-deletes":function($event){return _vm.handleDeletes($event)},"toggle-click":function($event){return _vm.handleDeletesToggle($event)}}}):_vm._e()],1),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.selected.length > 0 && _vm.config.multiUpdate)?_c('FloatTool',{attrs:{"process":_vm.model.toolConfig.processForm,"actions":_vm.model.selectActions(),"selected":_vm.selected},on:{"on-action":_vm.handleAction}}):_vm._e()],1),_vm._v(" "),_c('TableTools',{attrs:{"scanner":_vm.scanner,"data":_vm.tableData,"config":_vm.config,"type":_vm.model.type},on:{"add-click":function($event){return _vm.handleAddClick($event)}},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_vm._v(" "),_c('div',{staticClass:"row"},_vm._l((_vm.model.tableFields()),function(v,k){return _c('div',{key:k,staticClass:"cell header",style:({
      order: Object.keys(_vm.model.tableFields()).indexOf(k),
      justifyContent: _vm.getJustfication(k),
    })},[_c('div',{staticClass:"key",on:{"click":function($event){return _vm.sortDataBy(k)}}},[_vm._v("\n        "+_vm._s(v.header)+"\n      ")]),_vm._v(" "),(k == _vm.activeSort)?_c('div',{staticClass:"sort-active",domProps:{"innerHTML":_vm._s(_vm.sortIcon())}}):_vm._e()])}),0),_vm._v(" "),_c('transition-group',{attrs:{"name":"list-complete","tag":"ul"}},_vm._l((_vm.tableData),function(row){return _c('div',{key:JSON.stringify(row),staticClass:"row list-complete-item",class:{ selected: _vm.selected.includes(row) },on:{"click":function($event){return _vm.toggleSelected(row)}}},_vm._l((_vm.model.tableFields()),function(v,k){return _c('div',{key:k,staticClass:"cell",style:({
        width:
          'calc(100%/' +
          (Object.keys(_vm.model.tableFields()).length + 1) +
          ')',
        order: Object.keys(_vm.model.tableFields()).indexOf(k),
        justifyContent: _vm.getJustfication(k),
      })},[_c('div',{class:v.klass,style:(v.styler ? v.styler(row) : '')},[_vm._v("\n          "+_vm._s(v.formatter
              ? v.formatter(row, _vm.extractField(row, k))
              : _vm.extractField(row, k))+"\n          "),(v.klass == 'tooltip')?_c('div',{staticClass:"tooltip-content"},[_vm._v("\n            "+_vm._s(v.formatter
                ? v.formatter(row, _vm.extractField(row, k))
                : _vm.extractField(row, k))+"\n          ")]):_vm._e()])])}),0)}),0),_vm._v(" "),_c('div',{staticClass:"table-footer-actions"},[_c('div',{staticClass:"prev-items",class:{ disabled: (_vm.tablePage - 1) < 0 },on:{"click":function($event){return _vm.prevPage()}}},[_vm._v("Prev")]),_vm._v(" "),_c('div',{staticClass:"cur-row-count"},[_vm._v("Page "+_vm._s(_vm.tablePage + 1)+" of "+_vm._s(Math.ceil(_vm.currentRowCount / 10))+" ("+_vm._s(_vm.currentRowCount)+" items)")]),_vm._v(" "),_c('div',{staticClass:"next-items",class:{ disabled: (_vm.tablePage + 1) >= Math.ceil(_vm.currentRowCount / 10) },on:{"click":function($event){return _vm.nextPage()}}},[_vm._v("Next")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }